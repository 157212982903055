import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Home from './Home'
import Mint from './Mint'

const URL_MAP = {
  home: {
    path: '/',
    component: props => <Home {...props} />
  },
  mint: {
    path: '/mint',
    component: props => <Mint {...props} />
  }
}

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        {Object.values(URL_MAP).map(u =>
          <Route
            key={u.path}
            exact
            path={u.path}
            render={routeProps => u.component({ ...routeProps })}
          />
        )}
      </Switch>
    </Router>
  )
}
