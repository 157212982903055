
import { useState, useEffect } from 'react'
import Slider from '@material-ui/core/Slider';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Web3Modal from "web3modal";
import { ethers } from 'ethers'
import WalletConnectProvider from "@walletconnect/web3-provider";
import { abi } from './abi.js'

import './Mint.css'

const network = 'mainnet'
const contractAddress = "0xd227bfe8a3f2428c0c946d595a5de7fc02a3b423"

function Mint() {
  const [quantity, setQuantity] = useState(1)


  const mint = async () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "ddf1ca3700f34497bca2bf03607fde38" // required
        }
      }
    };

    const web3Modal = new Web3Modal({
      network, // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    const provider = await web3Modal.connect()

    const web3 = new ethers.providers.Web3Provider(provider)

    const { chainId } = await web3.getNetwork()
    if (chainId != 1) {
      alert('Error: Incorrect network. You need to switch to mainnet and try again.')
      return
    }

    const accts = await web3.listAccounts()
    const acct = accts[0]
    console.log(acct)

    const wordsContract = new ethers.Contract(contractAddress, abi, web3.getSigner());

    console.log(`minting ${quantity} tokens`)

    const pricePerWord = await wordsContract.wordPrice()
    const totalPrice = pricePerWord.mul(ethers.BigNumber.from(quantity))

    try {
      const tx = await wordsContract.mintWords(quantity, { value: totalPrice });
      console.log(tx)

    } catch (e) {
      console.log(e)
      if (e.code === "INSUFFICIENT_FUNDS") {
        alert('Insufficient funds')
      } else {
        alert(e.message)
      }
    }
  }

  return (
    <Container maxWidth='sm'>
      <div id='title'>
        Mint some words
      </div>
      <div style={{ height: 16 }} />
      <p style={{ fontSize: 32 }}>How many do you want to mint?</p>
      <div style={{ height: 16 }} />
      <Slider
        value={quantity}
        aria-labelledby="discrete-slider-small-steps"
        step={1}
        marks
        min={1}
        max={10}
        valueLabelDisplay="on"
        onChange={(e, v) => setQuantity(v)}
      />
      <div style={{ height: 32 }} />
      <Button onClick={mint} variant='contained' size='large'>Mint {quantity} Word{quantity > 1 ? 's' : ''}</Button>

      <div style={{ height: 32 }} />
      <div style={{ fontSize: 24 }}>
        <a href={`https://opensea.io/collection/gan-words`}>View the collection on OpenSea</a>
      </div>
    </Container>
  );
}

export default Mint;
