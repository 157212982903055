import logo from './logo.svg';
import './Home.css';

function Home() {
  return (
    <iframe style={{ border: 0, width: '100%', height: '100%' }} src="https://mmm.page/person.main" />
  );
}

export default Home;
